import React, { FunctionComponent } from "react"
import { graphql, PageProps } from "gatsby"
import * as SCM from "../types"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Edito from "../components/Edito"

type Props = PageProps & {
  data: {
    editoPage: SCM.EditoPage
  }
}

const EditoTemplate: FunctionComponent<Props> = props => {
  const editoPage = props.data.editoPage

  return (
    <Layout>
      <SEO
        locale={editoPage.locale}
        uri={props.uri}
        breadcrumb={[
          {
            position: 1,
            name: "Edito",
            slug: "/edito",
          },
        ]}
        {...editoPage.seo}
      />
      <Edito {...editoPage} />
    </Layout>
  )
}

export default EditoTemplate

export const query = graphql`
  query EditoPageQuery {
    editoPage {
      ...editoPageFragment
    }
  }
`
